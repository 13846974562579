<template>
    <header id="header" class="transparent-header floating-header header-size-md">
        <div id="header-wrap">
            <div class="container">
                <div class="header-row">

                    <!-- Logo
                    ============================================= -->
                    <div id="logo">
                        <a href="/" class="standard-logo" data-dark-logo="images/logo-dark.png"><img src="../assets/images/logo-tip.png" alt="Dartcomit Logo"></a>
                        <a href="/" class="retina-logo" data-dark-logo="images/logo-dark@2x.png"><img src="../assets/images/logo-tip.png" alt="Dartcomit Logo"></a>
                    </div><!-- #logo end -->

                    <div class="header-misc">
                        <a href="/contact" class="button button-rounded ml-3 d-none d-sm-block">Contact us</a>
                    </div>

                    <div id="primary-menu-trigger">
                        <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
                    </div>

                    <!-- Primary Navigation
                    ============================================= -->
                    <nav class="primary-menu with-arrows">

                        <ul class="menu-container">
                            <router-link
                                    to="/"
                                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                                    custom
                            >
                                <li :class="[isActive && 'current', isExactActive && 'router-link-exact-active', 'menu-item']" >
                                    <a class="menu-link" :href="href" @click="navigate">Home</a>
                                </li>
                            </router-link>
                            <router-link
                                    to="/apps"
                                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                                    custom
                            >
                                <li :class="[isActive && 'current', isExactActive && 'router-link-exact-active', 'menu-item']" >
                                    <a class="menu-link" :href="href" @click="navigate">Our Apps</a>
                                </li>
                            </router-link>
                            <li class="menu-item mega-menu"><div class="menu-link"><div>Services</div></div>
                                <div class="mega-menu-content mega-menu-style-2 px-0">
                                    <div class="container">
                                        <div class="row">
                                            <a href="/testing" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/seo.svg" alt="Testing" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Testing</h3>
                                                        <p>Automatic services</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/uiux" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/social.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">UI/UX</h3>
                                                        <p>User Design Experience and Interface</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/mobility" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/group.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Mobility</h3>
                                                        <p>Mobility Assessment</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/cloud" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/experience.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Cloud</h3>
                                                        <p>Cloud environment</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/web" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/analysis.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Web</h3>
                                                        <p>Product development</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a   href="/solutions" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-icon mb-2">
                                                        <img src="../assets/demos/seo/images/icons/content_marketing.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                                    </div>
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Solutions</h3>
                                                        <p>Partner for Corporate Mobility</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <router-link
                                    to="/solutions"
                                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                                    custom
                            >
                                <li :class="[isActive && 'current', isExactActive && 'router-link-exact-active', 'menu-item']" >
                                    <a class="menu-link" :href="href" @click="navigate">{{ route.name }}</a>
                                </li>
                            </router-link>
                        </ul>

                    </nav><!-- #primary-menu end -->


                </div>
            </div>
        </div>
        <div class="header-wrap-clone"></div>
    </header>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>

 img {
     image-orientation: none;
 }
</style>